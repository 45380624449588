<template>
  <component-frame title="Colours">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li><a href="#" v-scroll-to="'#brand'">Primary brand colours</a></li>
          <li><a href="#" v-scroll-to="'#grayscale'">Greyscale</a></li>
          <li><a href="#" v-scroll-to="'#ui-states'">UI &amp; states</a></li>
        </ul>
      </div>
    </div>

    <h3 id="brand">Primary Brand Colours</h3>

    <div class="row">
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--brand-primary"></div>
          <div class="card-body">
            <p>
              <small
                >brand-primary<br />
                <strong>#3858a4</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--brand-secondary"></div>
          <div class="card-body">
            <p>
              <small
                >brand-secondary<br />
                <strong>#8abf1c</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--brand-tertiary"></div>
          <div class="card-body">
            <p>
              <small
                >brand-tertiary<br />
                <strong>#3b9cef</strong></small
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <h3 id="grayscale" class="mt-3">Grayscale</h3>

    <div class="row">
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--black"></div>
          <div class="card-body">
            <p>
              <small
                >black<br />
                <strong>#2f353b</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--grey-darker"></div>
          <div class="card-body">
            <p>
              <small
                >gray-darker<br />
                <strong>#474b56</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--grey-dark"></div>
          <div class="card-body">
            <p>
              <small
                >gray-dark<br />
                <strong>#697177</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--grey"></div>
          <div class="card-body">
            <p>
              <small
                >gray<br />
                <strong>#adb6bc</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--grey-light"></div>
          <div class="card-body">
            <p>
              <small
                >gray-light<br />
                <strong>#c9d4db</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--grey-lighter"></div>
          <div class="card-body">
            <p>
              <small
                >gray-lighter<br />
                <strong>#e5ebf0</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--grey-lightest"></div>
          <div class="card-body">
            <p>
              <small
                >gray-lightest<br />
                <strong>#f1f4f7</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--white"></div>
          <div class="card-body">
            <p>
              <small
                >white<br />
                <strong>#ffffff</strong></small
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <h3 id="ui-states" class="mt-3">UI &amp; States</h3>

    <div class="row">
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--link"></div>
          <div class="card-body">
            <p>
              <small
                >link colour<br />
                <strong>#346caf</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--hover"></div>
          <div class="card-body">
            <p>
              <small
                >link hover,<br />
                neutral alert <strong>#3b9cef</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--success"></div>
          <div class="card-body">
            <p>
              <small
                >success<br />
                <strong>#8abf1c</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--error"></div>
          <div class="card-body">
            <p>
              <small
                >error<br />
                <strong>#cc1003</strong></small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mb-4">
        <div class="card">
          <div class="card-color card-color--warning"></div>
          <div class="card-body">
            <p>
              <small
                >warning<br />
                <strong>#fb8229</strong></small
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "colours",
  data() {
    return {
      title: "Colours"
    };
  }
};
</script>
